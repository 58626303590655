import { ActionCreatorPayload, ReducerFunction } from 'store/types';
import { AUTH_REDUCER_ACTIONS, AUTH_REDUCER_INITIAL_STATE, AuthState } from '../authTypes';

export const authStateCleared = (): ActionCreatorPayload => ({
  type: AUTH_REDUCER_ACTIONS.AUTH_STATE_CLEARED
});

export const authStateClearedReducer: ReducerFunction<AuthState, void> = (state) => ({
  ...state,
  ...AUTH_REDUCER_INITIAL_STATE
});
