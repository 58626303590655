import mixpanel from 'mixpanel-browser';
mixpanel.init('46c30ade1d77fa9bce319f2093f7f48a', {
  debug: process.env.REACT_APP_ENV !== 'production'
});

export const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (name: string, props?: { [key: string]: any }) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: { [key: string]: string | number | unknown }) => {
      mixpanel.people.set(props);
    }
  },
  getDistinctId: () => mixpanel.get_distinct_id()
};
