import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql } from 'core';
import actionTypes from './types';
import actions from './actions';
import { getLocalValue } from 'localization';
import { selectState } from 'store';
import Mixpanel from '../mixPanel';

export function* getUserDetailSaga() {
  yield put(actions.setUserDetailsPending());
  try {
    const {
      data: { userMarketplaceDetail }
    } = yield GraphQL.query({
      query: gql`
        query {
          userMarketplaceDetail {
            id
            tier
            userType
            productType
            receiveModelUpdateNotification
            maxSubscriptions
            allowedSectors
            firstName
            lastName
            email
            phoneNumber
            subscribedCount
          }
        }
      `,
      fetchPolicy: 'no-cache'
    });

    yield put(actions.setUserDetails(userMarketplaceDetail));

    if (userMarketplaceDetail.tier === 'free') {
      Mixpanel.track('marketplace:freeUser:logged_in', userMarketplaceDetail);
    } else {
      Mixpanel.track('marketplace:user:logged_in', userMarketplaceDetail);
    }
  } catch (error) {
    const geniricError: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    const errorMsg = (error as { message: string })?.message ?? geniricError;
    yield put(actions.setUserDetailsError(errorMsg));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.GET_USER_DETAILS, getUserDetailSaga);
}
