import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import routes from 'components/Router/routes';
import useLocal from 'localization';

import { ForgetPasswordSelectors } from './duck';
import { ForgetPasswordTypes } from './duck';
import { actions } from './duck';
import useStyles from './style';
import Mixpanel from '../../mixPanel';
import { useNavigate } from 'react-router';

const forgetPasswordObjectShape = Yup.object({
  email: Yup.string().email().required()
});

const initialValues = {
  email: ''
};

const ForgetPassword: React.FC = () => {
  const navigate = useNavigate();

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.requestResetPasswordClear());
    };
  }, [dispatch]);

  const hasError = useSelector(ForgetPasswordSelectors.hasError);
  const error = useSelector(ForgetPasswordSelectors.getError);
  const isLoading = useSelector(ForgetPasswordSelectors.isLoading);
  const isSuccess = useSelector(ForgetPasswordSelectors.isSuccess);
  const isFailure = useSelector(ForgetPasswordSelectors.isFailure);

  const forgetPasswordLinkSent = useLocal('forget_password_link_sent');
  const emailNotRegistered = useLocal('email_is_not_registered');

  const formikObj = useFormik<ForgetPasswordTypes.ForgetPasswordField>({
    initialValues,
    validateOnBlur: true,
    validationSchema: forgetPasswordObjectShape,
    onSubmit: ({ email }) => {
      Mixpanel.track('marketplace:forget_password:form:submit', { email });
      dispatch(actions.requestResetPassword(email));
    }
  });

  return (
    <form onSubmit={formikObj.handleSubmit}>
      <Dialog maxWidth="xs" fullWidth open={true} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
          <h2>{useLocal('forget_password')}</h2>
          <IconButton data-testid="api-settings-close-btn" onClick={() => navigate(routes.LOGIN)}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        {hasError && (
          <Alert severity="error" data-testid="server-error">
            {error}
          </Alert>
        )}
        {isSuccess && (
          <Alert severity="success" data-testid="server-error">
            {forgetPasswordLinkSent}
          </Alert>
        )}
        {isFailure && (
          <Alert severity="error" data-testid="server-error">
            {emailNotRegistered}
          </Alert>
        )}
        <DialogContent>
          <Box py={3}>
            <Typography color="textSecondary">{useLocal('forget_password_text')}</Typography>
          </Box>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="email" error={false}>
              {useLocal('login_email')}
            </InputLabel>
            <OutlinedInput
              id="email"
              inputProps={{ 'data-testid': 'forget-password-email' }}
              name="email"
              type="text"
              labelWidth={103}
              onChange={formikObj.handleChange}
              value={formikObj.values.email}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.email && formikObj.touched.email)}
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.email && formikObj.touched.email)}
            >
              {formikObj.touched.email && formikObj.errors.email}
            </FormHelperText>
          </FormControl>

          <DialogActions className={classes.dialogActions}>
            <Button
              disabled={isLoading}
              onClick={formikObj.submitForm}
              type="submit"
              color="primary"
              data-testid="forget-password-submit"
              variant="contained"
            >
              {useLocal('reset_btn')}
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default ForgetPassword;
