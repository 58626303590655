import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import EarningsUpdateActionNotifications from 'components/EarningsUpdateActionNotifications/EarningsUpdateActionNotifications';
import {
  BellIcon,
  BookIcon,
  DaloopaLogoIcon,
  DaloopaPlusLogoIcon,
  HomeIcon,
  InstanceIcon,
  LoginIcon,
  LogoutIcon,
  MailIcon,
  MenuCollapseIcon,
  MenuExpandIcon,
  SettingsIcon
} from 'components/Icons';
import routes from 'components/Router/routes';
import { getUserInfo, GraphQL, redirectToSSOUrl } from 'core';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import loginActions from 'pages/Login/duck/actions';
import { UPDATE_NOTIFICATION_SUBSCRIPTION } from 'pages/Models/constants';
import { updateNotification } from 'pages/Models/duck/types';
import { Mixpanel } from '../../infra/mixpanel';
import loginSelectors from '../../pages/Login/duck/selector';
import { modelsSelectors } from '../../pages/Models/duck/';
import modelsActions from '../../pages/Models/duck/actions';
import notificationsSelector from '../../pages/Models/duck/selector';
import {
  Container,
  ContentWrapper,
  MenuButton,
  MenuHeader,
  MenuMainNavigation,
  MenuSubNavigation,
  NotificationBadge,
  OldMarketPlaceButton
} from './MenuV2.styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { setMenuState } from 'store';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { useStatsigClient } from '@statsig/react-bindings';

export const MenuV2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const statsig = useStatsigClient().client;

  const notificationButtonRef = useRef<HTMLButtonElement>(null);

  const [expanded, setExpanded] = useState(useAppSelector(generalState).menuExpanded);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const { isAuthenticated } = isLoggedIn();
  const isUserLoggedIn = useSelector(loginSelectors.isUserLoggedIn);
  // const isFreeTier = useSelector(UserDetailSelectors.isFreeTier);

  const isEarningNotificationsEnabled = useSelector(modelsSelectors.isEarningNotificationsEnabled);
  const NOTIFICATIONS_POLLING = process.env.REACT_APP_NOTIFICATIONS_POLLING === 'true';
  const info = getUserInfo();

  const isPlus = useMemo(
    () => info?.productType === 'daloopa_plus' || info?.productType === 'daloopa_plus_time_ltd',
    [info]
  );

  const earningsUpdateNotificationsUnseenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );

  const notificationsCount = useMemo(
    () =>
      earningsUpdateNotificationsUnseenCount > 999
        ? '999+'
        : earningsUpdateNotificationsUnseenCount,
    [earningsUpdateNotificationsUnseenCount]
  );

  const isSrcPage = location.pathname.includes('/src/');

  const handleToggleMenu = () => {
    setExpanded((prev) => {
      const state = !prev;

      Mixpanel.track('marketplaceV2:menu:toggle_state', { state });

      dispatch(setMenuState(state));
      return state;
    });
  };

  const handleLoginClick = () => {
    Mixpanel.track('marketplaceV2:menu:login');
    if (USE_SSO_LOGIN) {
      redirectToSSOUrl();
    }
  };
  const handleLogout = () => {
    Mixpanel.track('marketplaceV2:menu:logout');
    dispatch(loginActions.logout());
  };

  const handleSettings = () => {
    Mixpanel.track('marketplaceV2:menu:settings');
    navigate(routes.SETTINGS);
  };

  const handleShowNotifications = () => {
    setNotificationsOpen(true);
    Mixpanel.track('marketplaceV2:menu:notification_bell_icon:click');
  };
  const handleHideNotifications = () => {
    setNotificationsOpen(false);
    Mixpanel.track('marketplaceV2:menu:notifications:close');
  };
  const handleDocumentationClick = () => {
    Mixpanel.track('marketplaceV2:menu:documentation');
  };
  const handleSupportClick = () => {
    Mixpanel.track('marketplaceV2:menu:support');
  };

  const handleOldMarketplaceClick = () => {
    Mixpanel.track('marketplaceV2:menu:old_marketplace');
  };

  // const handleDownloadsClick = () => {
  //   Mixpanel.track('marketplaceV2:menu:downloads', { isFreeTier });
  // };

  useEffect(() => {
    if (isAuthenticated && isEarningNotificationsEnabled && NOTIFICATIONS_POLLING && !isSrcPage) {
      dispatch(modelsActions.getUpdateNotifications());
    } else {
      dispatch(modelsActions.stopPolling());
    }
  }, [
    isEarningNotificationsEnabled,
    isAuthenticated,
    NOTIFICATIONS_POLLING,
    isUserLoggedIn,
    isSrcPage,
    dispatch
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(modelsActions.getEarningUpdateNotificationConfig());
    }
  }, [dispatch, isAuthenticated]);

  useSubscription<{
    earningUpdateNotifications: updateNotification[];
  }>(UPDATE_NOTIFICATION_SUBSCRIPTION, {
    skip:
      !isUserLoggedIn ||
      !isAuthenticated ||
      !isEarningNotificationsEnabled ||
      NOTIFICATIONS_POLLING,
    shouldResubscribe: false,
    client: GraphQL as any,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data && data.earningUpdateNotifications) {
        dispatch(modelsActions.getUpdateNotificationSuccess(data.earningUpdateNotifications));
      }
    }
  });

  return (
    <Container open={expanded}>
      <ContentWrapper>
        <MenuHeader>
          {expanded && (
            <>
              {!isPlus && <DaloopaLogoIcon className="daloopa-icon" />}
              {isPlus && <DaloopaPlusLogoIcon className="daloopa-icon" />}
            </>
          )}
          <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
            <MenuButton className="menu-toggle" onClick={handleToggleMenu}>
              {expanded ? <MenuCollapseIcon /> : <MenuExpandIcon />}
            </MenuButton>
          </Tooltip>
        </MenuHeader>
        <MenuMainNavigation>
          <Link to={routes.HOME}>
            <Tooltip title="Home">
              <MenuButton onClick={(e) => e.currentTarget.blur()}>
                <HomeIcon />
                {expanded && <div>Home</div>}
              </MenuButton>
            </Tooltip>
          </Link>
          {/* <Link to={isFreeTier ? '#' : routes.EXCEL_PLUGIN} onClick={handleDownloadsClick}>
            <Tooltip title="Downloads">
              <MenuButton disabled={isFreeTier}>
                <DownloadIcon />
                {expanded && <div>Downloads</div>}
              </MenuButton>
            </Tooltip>
          </Link> */}
          {process.env.REACT_APP_HELP_PAGE_LINK && (
            <a
              href={process.env.REACT_APP_HELP_PAGE_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={handleDocumentationClick}
            >
              <Tooltip title="Documentation">
                <MenuButton>
                  <BookIcon />
                  {expanded && <div>Documentation</div>}
                </MenuButton>
              </Tooltip>
            </a>
          )}
        </MenuMainNavigation>
        <MenuSubNavigation>
          <a
            href="mailto:support@daloopa.com"
            target="_blank"
            rel="noreferrer"
            onClick={handleSupportClick}
          >
            <Tooltip title="Support">
              <MenuButton>
                <MailIcon />
                {expanded && <div>Support</div>}
              </MenuButton>
            </Tooltip>
          </a>
          {isAuthenticated && (
            <>
              <Tooltip title="Alerts">
                <MenuButton ref={notificationButtonRef} onClick={handleShowNotifications}>
                  <BellIcon />
                  {expanded && <div>Alerts</div>}
                  {!!notificationsCount && (
                    <NotificationBadge className="notifications-badge">
                      {notificationsCount}
                    </NotificationBadge>
                  )}
                </MenuButton>
              </Tooltip>
              <EarningsUpdateActionNotifications
                anchorEl={notificationButtonRef.current}
                open={notificationsOpen}
                onClose={handleHideNotifications}
              />
              {statsig.checkGate('enable-marketplace-v2-settings') && (
                <Tooltip title="Settings">
                  <MenuButton onClick={handleSettings}>
                    <SettingsIcon />
                    {expanded && <div>Settings</div>}
                  </MenuButton>
                </Tooltip>
              )}
              <Tooltip title="Logout">
                <MenuButton onClick={handleLogout}>
                  <LogoutIcon />
                  {expanded && <div>Logout</div>}
                </MenuButton>
              </Tooltip>
            </>
          )}
          {!isAuthenticated && (
            <Link
              to={USE_SSO_LOGIN ? '#' : routes.LOGIN}
              state={{ from: location.pathname.concat(location.search) }}
            >
              <Tooltip title="Login">
                <MenuButton onClick={handleLoginClick}>
                  <LoginIcon />
                  {expanded && <div>Login</div>}
                </MenuButton>
              </Tooltip>
            </Link>
          )}
          <Tooltip title="Switch to old Marketplace">
            <OldMarketPlaceButton
              href={`${routes.HOME}?v1=true`}
              target="_blank"
              rel="noreferrer"
              onClick={handleOldMarketplaceClick}
            >
              <InstanceIcon />
              {expanded && <span>Switch to old Marketplace</span>}
            </OldMarketPlaceButton>
          </Tooltip>
        </MenuSubNavigation>
      </ContentWrapper>
    </Container>
  );
};
