import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Menu as MetrialMenu } from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { redirectToLoginUrl, redirectToSSOUrl } from 'core';
import { Badge, Button } from '@material-ui/core';
import useLocal from 'localization';
import { SET_SHOW_TRANSLATION_KEYS } from 'localization/reducer';
import routes from 'components/Router/routes';
import ExcelIcon from 'components/ExcelIcon';
import { actions as authAction } from 'pages/Login/duck';
import useStyles from './style';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import Classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { UserDetailSelectors } from 'userDetail';
import WhiteTooltip from 'components/WhiteTooltip';
import ModelFreeTrierTooltip from 'components/ModelFreeTrierTooltip';
import { Notifications } from '@material-ui/icons';
import notificationsSelector from '../../pages/Models/duck/selector';
import EarningsUpdateActionNotifications from '../../components/EarningsUpdateActionNotifications';
import Mixpanel from '../../mixPanel';

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const classes = useStyles();
  const StyledTab = withStyles((theme: Theme) => ({
    wrapper: {
      flexDirection: 'row',
      background: theme.palette.primary.dark,
      justifyContent: 'space-evenly',
      width: '70%',
      paddingTop: theme.spacing(0.7),
      alignItems: 'flex-start'
    }
  }))(Tab);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  // memoizing is currently breaking after login as the component is
  const _isLoggedIn = isLoggedIn().isAuthenticated;

  const modelText = useLocal('model');
  const apiSettingsText = useLocal('api_settings');
  const kpiKey = useLocal('kpi');
  const supportText = useLocal('support');

  const logoutText = useLocal('logout');
  const loginText = useLocal('login');
  const pluginText = useLocal('plugin');
  const documentationText = useLocal('documentation');
  const isFreeTier = useSelector(UserDetailSelectors.isFreeTier);
  const earningsUpdateNotificationsUnSeenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);

  const onAccountLinkClick = (event: React.MouseEvent) => {
    Mixpanel.track('marketplace:menu:account_menu:click');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(anchorEl ? null : event.currentTarget);
    Mixpanel.track('marketplace:menu:notification_bell_icon:click');
  };

  const onModelsClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:models:click');
  }, []);

  const onSupportClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:support:click');
    window.open('mailto:support@daloopa.com');
  }, []);

  const onAPISettingsClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:api_settings:click', { isLoggedIn: _isLoggedIn });
    if (!_isLoggedIn) {
      redirectToLoginUrl();
    }
  }, [_isLoggedIn]);

  const onKPILinkClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:kpi_link:click');
  }, []);

  const onDocumentationLinkClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:documentation_link:click');
  }, []);

  const onLogoutClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:logout:click');
    dispatch(authAction.logout());
  }, [dispatch]);

  const onLoginClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:login:click');
    if (USE_SSO_LOGIN) {
      redirectToSSOUrl();
    } else {
      handleClose();
      navigate(routes.LOGIN, { state: { from: location.pathname.concat(location.search) } });
    }
  }, [handleClose, navigate]);

  const onPluginClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:excel_plugin:click');
    navigate(routes.EXCEL_PLUGIN);
  }, [navigate]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed['showTranslationKeys'] === 'true') {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: true
      });
    } else {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: false
      });
    }
  }, [dispatch, location.search]);

  return (
    <Tabs value={location.pathname !== routes.HOME ? location.pathname : false}>
      <Tab
        label={modelText}
        value={routes.MODELS}
        component={Link}
        to={routes.MODELS}
        className={classes.menuLink}
        data-testid="model-page-link"
        onClick={onModelsClick}
      />
      <Tab label={supportText} className={classes.menuLink} onClick={onSupportClick} />

      {_isLoggedIn ? (
        <Tab
          label={apiSettingsText}
          value={routes.API_SETTINGS}
          component={Link}
          to={routes.API_SETTINGS}
          className={classes.menuLink}
          data-testid="api-settings-link"
          onClick={onAPISettingsClick}
        />
      ) : (
        <Button
          variant={'text'}
          className={Classnames(classes.menuLink, classes.apiSettingsButton)}
          data-testid="api-settings-link"
          onClick={onAPISettingsClick}
        >
          {apiSettingsText}
        </Button>
      )}
      <Tab
        label={kpiKey}
        component="a"
        href={process.env.REACT_APP_KPI_LINK}
        className={classes.menuLink}
        data-kpi="kpi-link"
        target="_blank"
        onClick={onKPILinkClick}
      />
      <Tab
        label={documentationText}
        component="a"
        href={process.env.REACT_APP_HELP_PAGE_LINK}
        className={classes.menuLink}
        data-testid="help-page-link"
        target="_blank"
        onClick={onDocumentationLinkClick}
      />
      <Tab className={classes.iconLink} icon={<AccountCircleIcon />} onClick={onAccountLinkClick} />

      {_isLoggedIn && (
        <Tab
          className={classes.iconLink}
          icon={
            <Badge badgeContent={earningsUpdateNotificationsUnSeenCount} color={'error'}>
              <Notifications />
            </Badge>
          }
          onClick={handleNotificationClick}
        />
      )}

      <EarningsUpdateActionNotifications
        anchorEl={notificationAnchorEl}
        onClose={() => setNotificationAnchorEl(null)}
      />

      <MetrialMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {_isLoggedIn && <MenuItem onClick={onLogoutClick}>{logoutText}</MenuItem>}
        {!_isLoggedIn && <MenuItem onClick={onLoginClick}>{loginText}</MenuItem>}
      </MetrialMenu>
      {isFreeTier ? (
        <WhiteTooltip title={<ModelFreeTrierTooltip showNotAllowed />} interactive>
          <span>
            <StyledTab icon={<ExcelIcon />} label={pluginText} disabled onClick={onPluginClick} />
          </span>
        </WhiteTooltip>
      ) : (
        <StyledTab icon={<ExcelIcon />} label={pluginText} onClick={onPluginClick} />
      )}
    </Tabs>
  );
};
export default Menu;
