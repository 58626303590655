import React, { lazy } from 'react';
import { routes } from '../routes';
import { RouteObject } from 'react-router';

const ErrorPage = lazy(async () => import('../../../components/ErrorPage/index'));

export const errorRoutes: RouteObject[] = [
  {
    path: routes.ERROR_404,
    element: <ErrorPage errorType="404" />
  },
  {
    path: routes.ERROR_500,
    element: <ErrorPage errorType="500" />
  },
  {
    path: routes.ERROR_401,
    element: <ErrorPage errorType="401" />
  },
  {
    path: routes.ERROR_403,
    element: <ErrorPage errorType="403" />
  }
];
