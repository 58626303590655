import React, { lazy } from 'react';
import { routes } from '../routes';
import { Navigate, RouteObject } from 'react-router';

const InquireHerePopupPage = lazy(async () => import('../../../pages/InquireHerePopup'));
const RegisterThankYouPopupPage = lazy(
  async () => import('../../../components/RegisterThankYouPopup')
);
const HomePage = lazy(async () => import('../../../presentation/pages/home/HomePage'));
const SearchPage = lazy(async () => import('../../../presentation/pages/search/SearchPage'));

export const openRoutes: RouteObject[] = [
  {
    path: routes.INQUIRE_HERE_POPUP,
    element: <InquireHerePopupPage />
  },
  {
    path: routes.REGISTER_THANKYOU_POPUP,
    element: <RegisterThankYouPopupPage />
  },
  {
    path: routes.SEARCH,
    element: <SearchPage />
  },
  {
    path: routes.HOME,
    element: <HomePage />
  },
  {
    path: '*',
    element: <Navigate to={routes.HOME} />
  }
];
