import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { isLoggedIn } from 'core/modules/auth.utils';

if (
  process.env.REACT_APP_DATADOG_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
  process.env.REACT_APP_ENV &&
  process.env.NODE_ENV !== 'development'
) {
  // Use the same env as the backend
  const env = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'staging-client-facing';

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'marketplace',
    allowedTracingUrls: [/https:\/\/.*\.daloopa\.com/],
    env,
    enableExperimentalFeatures: ['feature_flags'],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 5,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackSessionAcrossSubdomains: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
}

type Props = {
  children: React.ReactNode;
};

export const DatadogProvider = ({ children }: Props) => {
  const { isAuthenticated, tokenData } = isLoggedIn();

  useEffect(() => {
    if (isAuthenticated) {
      datadogRum.setUser({
        id: tokenData?.email,
        email: tokenData?.email
      });
    } else {
      datadogRum.clearUser();
    }
  }, [isAuthenticated, tokenData?.email]);

  return <>{children}</>;
};
