import { RouteObject } from 'react-router';
import { authRoutes } from './auth-routes';
import { errorRoutes } from './error-routes';
import { openRoutes } from './open-routes';
import { protectedRoutes } from './protected-routes';

export const appRoutes: RouteObject[] = [
  ...errorRoutes,
  ...authRoutes,
  ...protectedRoutes,
  ...openRoutes
];
