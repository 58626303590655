import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql } from 'core';
import actionTypes, { getFundamentalInfoAction, getCompositeFundamentalAction } from './types';
import actions from './actions';
import Mixpanel from '../../../mixPanel';

export function* getFundamentalInfoSaga({
  fundamentalId,
  isDocument
}: ReturnType<getFundamentalInfoAction>) {
  yield put(actions.setFundamentalInfoPending());
  try {
    const {
      data: { fundamentalInfo }
    } = yield GraphQL.query({
      query: gql`
        query getFundamentalInfo($fundamentalId: Int!, $isDocument: Boolean!) {
          fundamentalInfo(fundamentalId: $fundamentalId, isDocument: $isDocument) {
            type
            visibility
            period
            value
            document {
              reportedDate
              filingType
              documentType
              url
            }
            company {
              ticker
              name
            }
          }
        }
      `,
      variables: { fundamentalId: parseInt(fundamentalId), isDocument },
      fetchPolicy: 'cache-first'
    });

    if (fundamentalInfo) {
      Mixpanel.track('marketplace:reader:view', fundamentalInfo?.company);
      if (fundamentalInfo.type === 'COMPOSITE') {
        yield put(actions.getCompositeFundamental(fundamentalId));
      }
      yield put(actions.setFundamentalInfo(fundamentalInfo));
    }
  } catch (error) {
    yield put(actions.setFundamentalInfoError((error as { message: string })?.message));
  }
}

export function* getCompositeFundamentalSaga({
  fundamentalId
}: ReturnType<getCompositeFundamentalAction>) {
  yield put(actions.setFundamentalInfoPending());
  try {
    const {
      data: { compositeFundamentals }
    } = yield GraphQL.query({
      query: gql`
        query getCompositeFundamentals($fundamentalId: Int!) {
          compositeFundamentals(fundamentalId: $fundamentalId) {
            attributeName
            operations {
              value
              period
              operator
              id
            }
            result {
              value
              period
            }
          }
        }
      `,
      variables: { fundamentalId: parseInt(fundamentalId) },
      fetchPolicy: 'cache-first'
    });

    if (compositeFundamentals) {
      yield put(actions.setCompositeFundamental(compositeFundamentals));
    }
  } catch (error) {
    yield put(actions.setFundamentalInfoError((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.GET_FUNDAMENTAL_INFO, getFundamentalInfoSaga);
  yield takeLatest(actionTypes.GET_COMPOSITE_FUNDAMENTAL, getCompositeFundamentalSaga);
}
