import { ServiceCommand } from 'domain/service-command';
import { UserIPValidation } from './user-ip-validation';
import { httpClient } from 'infra/http/rest/';

const route = 'validate-ip';

export const userIPValidationService: ServiceCommand<UserIPValidation.Response> = new UserIPValidation(
  httpClient,
  route
);

export type { UserIPValidation };
