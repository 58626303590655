import React, { createContext, useContext, useMemo } from 'react';
import {
  Id,
  ToastContainer,
  ToastContent,
  ToastOptions,
  toast as toastifyToast
} from 'react-toastify';

import { Button, styled } from '@mui/material';
import { CloseIcon } from 'components/Icons';

import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext<{
  (content: ToastContent<unknown>): Id;
  success(content: ToastContent<unknown>): Id;
  error(content: ToastContent<unknown>): Id;
} | null>(null);

const CloseButton = styled('button')(() => ({
  height: '24px',
  width: '24px',
  color: '#FFFFFF',
  opacity: 1,
  margin: 'auto 0',

  '& svg': {
    width: '24px',
    height: '24px'
  }
}));

const StyledToastContainer = styled(ToastContainer)(() => ({
  width: 'fit-content',
  minWidth: '354px',

  '& .Toastify__toast': {
    minHeight: 'unset',
    borderRadius: '4px',
    background: '#292B2E',
    color: '#FFFFFF'
  },

  '& .Toastify__toast-body': {
    fontSize: '14px',
    fontWeight: 'bold',

    '&> div': {
      display: 'flex',
      alignItems: 'center'
    }
  },

  '& .Toastify__toast-icon': {
    '& svg': {
      height: '20px',
      width: '20px'
    }
  },

  '& .Toastify__toast--error .Toastify__toast-icon': {
    color: '#F45C3A',

    '& svg': {
      height: '20px',
      width: '20px'
    }
  }
}));

const ToastTryAgainButton = styled('a')(() => ({
  marginLeft: '4px',
  color: '#FFFFFF',

  '&:hover': {
    color: 'red'
  },

  '&:first-of-type': {
    marginRight: '4px'
  }
}));

const ToastUndoButton = styled(Button)(() => ({
  margin: '0 0 auto auto',
  color: '#FFFFFF',
  padding: '0',
  background: 'transparent',

  '&:hover': {
    color: '#8FC4C2'
  }
}));

export const ToastMessage = ({ message, onUndo }: { message: string; onUndo?: () => void }) => (
  <>
    {message}
    {onUndo && <ToastUndoButton onClick={onUndo}>UNDO</ToastUndoButton>}
  </>
);

export const ToastError = ({ message, onRetry }: { message?: string; onRetry?: () => void }) => {
  const onRefresh = () => location.reload();

  if (message) {
    return (
      <div>
        {message}
        {onRetry && <ToastTryAgainButton onClick={onRetry}>Try again</ToastTryAgainButton>}
      </div>
    );
  }

  return (
    <div>
      Something went wrong. Please
      <ToastTryAgainButton onClick={onRefresh}>refresh</ToastTryAgainButton>
      or
      {onRetry && <ToastTryAgainButton onClick={onRetry}>try again</ToastTryAgainButton>}
      {!onRetry && ' try again'}.
    </div>
  );
};

type Props = {
  children: React.ReactNode;
};

export const ToastContextProvider = ({ children }: Props) => {
  const config: ToastOptions = {
    autoClose: 3000,
    hideProgressBar: true,
    closeButton() {
      return (
        <CloseButton className="Toastify__close-button" aria-label="close">
          <CloseIcon />
        </CloseButton>
      );
    }
  };

  const toast = (content: ToastContent<unknown>) => toastifyToast(content, config);

  toast.success = (content: ToastContent<unknown>) => toastifyToast.success(content, { ...config });

  toast.error = (content: ToastContent<unknown>) => toastifyToast.error(content, { ...config });

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <StyledToastContainer />
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToastContext is only available within ToastContext Provider');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => context, []);
};
