import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import { App } from './App';
import { StatsigProvider } from 'infra/statsig';
import { ApolloProvider, GraphQL } from 'core';
import { CircularProgress } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { DatadogProvider } from 'infra/datadog';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Suspense fallback={<CircularProgress size={24} style={{ margin: '48px auto' }} />}>
        <Provider store={store}>
          <ApolloProvider client={GraphQL}>
            <StatsigProvider>
              <DatadogProvider>
                <App />
              </DatadogProvider>
            </StatsigProvider>
          </ApolloProvider>
        </Provider>
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);
