import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';

import SubHeader from 'components/SubHeader';
import TvSidebar from 'components/TvSidebar';
import CompositeFundamental from 'components/CompositeFundamental';
import useLocal from 'localization';
import { Axios } from 'core';
import { CircularProgress, Link } from '@material-ui/core';
import Mixpanel from '../../mixPanel';
import { updateQueryParams } from 'utils';

import { TagViewerSelectors } from './duck';
import { TagViewerTypes } from './duck';
import { actions } from './duck';
import useStyles from './style';

const TagViewer: React.FC<TagViewerTypes.TagViewerProps> = ({ isDocument }) => {
  const dispatch = useDispatch();
  const tagViewerLocal = useLocal('tag_viewer');
  const { search } = useLocation();
  const { id } = useParams<{ id?: string; snippet?: string }>();

  const classes = useStyles();

  const iframe = useRef<HTMLIFrameElement>(null);

  const hasError = useSelector(TagViewerSelectors.hasError);
  const error = useSelector(TagViewerSelectors.getError);
  const fundamentlInfo = useSelector(TagViewerSelectors.getFundamentlInfo);
  const compositeFundamental = useSelector(TagViewerSelectors.getCompositeFundamental);

  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const isExcelPlugin = urlParams.get('excel_plugin');
  const isSnippet = useMemo(() => urlParams.get('snippet'), [urlParams]);

  const [iframeSrcDoc, setIframeSrcDoc] = useState('');
  const [iframeSrcLoading, setIframeSrcLoading] = useState(true);

  const [snippetMode, setSnippetMode] = useState(isSnippet === 'true');
  const [modeLoaded, setModeLoaded] = useState(false);

  const isLoading = useSelector(TagViewerSelectors.isLoading) && !modeLoaded;

  const isExcelPluginSrc = isExcelPlugin === 'true';

  const printAsPdf = () => {
    if (iframe?.current?.contentWindow) {
      iframe?.current?.contentWindow.postMessage('print', '*');
    } else {
      window.print();
    }
  };

  const locateDatapoint = () => {
    iframe?.current?.contentWindow?.postMessage('locate', '*');
  };

  const handleToggleMode = () => {
    setSnippetMode((oldVal) => {
      localStorage.setItem('snippet-toggle-mode', `${!oldVal}`);
      return !oldVal;
    });
  };

  useEffect(() => {
    if (!modeLoaded) {
      if (!isSnippet) {
        const snippetModeLocal = localStorage.getItem('snippet-toggle-mode') as string;
        const mode = snippetModeLocal ? snippetModeLocal === 'true' : true; // Defaults to true if no value is available.
        setSnippetMode(mode);
      }
      setModeLoaded(true);
    }
  }, [isSnippet, modeLoaded]);

  useEffect(() => {
    if (!modeLoaded) return;

    updateQueryParams({ snippet: snippetMode });
    const srcUrl = snippetMode
      ? `${process.env.REACT_APP_BACKEND_URL}/text_fundamental?id=${id}`
      : `${process.env.REACT_APP_BACKEND_URL}/embed/source/${id}`;

    const url = isDocument ? `${process.env.REACT_APP_BACKEND_URL}/embed/document/${id}` : srcUrl;
    const loadIframeContent = () => {
      setIframeSrcLoading(true);
      Axios.get(url)
        .then((response) => {
          let content = (response as { data?: string })?.data ?? '';
          if (content) {
            content = content.replaceAll(
              '/static/',
              `${process.env.REACT_APP_BACKEND_URL}/static/`
            );
            if (snippetMode) {
              content = content
                .replaceAll('class="navbar', 'style="display: none"')
                .replace(/onclick="callParent\(\d+\)"/i, 'style="display: none"');
            }

            content = content.replaceAll(/href="(#if.*?)"/gim, (substring, href) => {
              return `href="about:srcdoc${href}"`;
            });
            setIframeSrcDoc(content);
          }
        })
        .catch((error) => {
          setIframeSrcDoc('');
          alert(error);
        })
        .finally(() => {
          setIframeSrcLoading(false);
        });
    };
    if (url) {
      loadIframeContent();
    }
  }, [snippetMode, id, isDocument, modeLoaded]);

  useEffect(() => {
    if (id) {
      dispatch(actions.getFundamentalInfo(id, isDocument ?? false));
    }
    if (fundamentlInfo) {
      Mixpanel.track('marketplace:src_view', fundamentlInfo?.company);
    }
  }, [dispatch, id, isDocument, fundamentlInfo]);

  const renderView = () => {
    if (fundamentlInfo?.type === 'COMPOSITE') {
      return (
        <>
          {compositeFundamental && (
            <CompositeFundamental compositeFundamental={compositeFundamental} />
          )}
        </>
      );
    } else if (!!fundamentlInfo && fundamentlInfo?.type === 'FILLER_VALUE') {
      return (
        <>
          {'This value is either zero or not disclosed, please check the filing here '}
          {!!fundamentlInfo.document && (
            <Link href={fundamentlInfo.document.url} underline="always" target={'_blank'}>
              {fundamentlInfo.document.documentType}
            </Link>
          )}
        </>
      );
    } else if (iframeSrcLoading) {
      return (
        <Box className={classes.progressWrapper}>
          <CircularProgress />
        </Box>
      );
    } else if (iframeSrcDoc) {
      return (
        <iframe
          srcDoc={iframeSrcDoc}
          className={classes.iframe}
          title="tag-viewer"
          ref={iframe}
          src={window.location.href}
        />
      );
    }
  };

  return (
    <>
      {!isExcelPluginSrc && (
        <Box pt={{ xs: 18, sm: 15 }}>
          <SubHeader title={tagViewerLocal} />
          <Container className={classes.container} maxWidth="xl" data-testid="page-container">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3} md={2} key="sidebar-box">
                {hasError && <Alert severity="error">{error}</Alert>}
                {isLoading && <Skeleton variant="rect" height={700} />}
                {fundamentlInfo?.company && !hasError && !isLoading && (
                  <TvSidebar
                    printAsPdf={printAsPdf}
                    fundamentlInfo={fundamentlInfo}
                    locateDatapoint={locateDatapoint}
                    isDocument={isDocument}
                    snippetMode={snippetMode}
                    onToggleMode={handleToggleMode}
                    id={id}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={9} md={10} key="content-box">
                {hasError && <Alert severity="error">{error}</Alert>}
                {isLoading && <Skeleton variant="rect" height={700} />}
                {!isLoading && !hasError && (
                  <Box bgcolor="white.main" height="80vh">
                    {renderView()}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      {isExcelPluginSrc && (
        <Box>
          <Grid item key="content-box">
            {hasError && <Alert severity="error">{error}</Alert>}
            {isLoading && <Skeleton variant="rect" height={2000} />}
            {!isLoading && !hasError && (
              <Box bgcolor="white.main" height="100vh">
                {renderView()}
              </Box>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TagViewer;
