import React, { lazy } from 'react';
import { routes } from '../routes';
import { RouteObject } from 'react-router';
import { ProtectedRoute } from '../ProtectedRoute';

const SettingsPage = lazy(async () => import('../../../presentation/pages/settings/SettingsPage'));
const LiveModelPreviewPage = lazy(async () => import('../../../pages/LiveModelPreview'));
const TagViewerPage = lazy(async () => import('../../../pages/TagViewer'));
const TextFundamentalPage = lazy(async () => import('../../../pages/TextFundamental'));
const DashboardPage = lazy(async () => import('../../../pages/Dashboard'));
const RolledUpNumbersPage = lazy(async () => import('../../../pages/RolledUpNumbers'));

export const protectedRoutes: RouteObject[] = [
  {
    path: routes.SETTINGS,
    element: (
      <ProtectedRoute>
        <SettingsPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.LIVE_PREVIEW,
    element: (
      <ProtectedRoute redirectToV1>
        <LiveModelPreviewPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.TAG_VIEWER,
    element: (
      <ProtectedRoute redirectToV1>
        <TagViewerPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.DOC_VIEWER,
    element: (
      <ProtectedRoute redirectToV1>
        <TagViewerPage isDocument />
      </ProtectedRoute>
    )
  },
  {
    path: routes.TEXT_FUNDAMENTAL,
    element: (
      <ProtectedRoute redirectToV1>
        <TextFundamentalPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.DASHBOARD,
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.ROLLUP_NUMBERS,
    element: (
      <ProtectedRoute>
        <RolledUpNumbersPage />
      </ProtectedRoute>
    )
  }
];
