import React from 'react';

import { ENUMS } from './constants';

import usePopupState from './hooks/usePopupState';
import DemoPopup from 'components/DemoPopup';
import UnSubscribeModelPopup from 'components/UnSubscribeModelPopup';
import RequestModel from 'pages/RequestModel';
import RequestNewModel from 'pages/RequestNewModel';
import ApiSettings from 'pages/ApiSettings';
import ExcelPlugin from 'pages/ExcelPlugin';
import DownloadModel from 'pages/DownloadModel';
import routes from 'components/Router/routes';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';

const popups = {
  [ENUMS.popup.demoPopup]: { component: DemoPopup, loginRequired: true },
  [ENUMS.popup.requestModel]: { component: RequestModel, loginRequired: true },
  [ENUMS.popup.addNewModel]: { component: RequestNewModel, loginRequired: true },
  [ENUMS.popup.apiSettings]: { component: ApiSettings, loginRequired: true },
  [ENUMS.popup.excelPlugin]: { component: ExcelPlugin, loginRequired: true },
  [ENUMS.popup.downloadModel]: { component: DownloadModel, loginRequired: true },
  [ENUMS.popup.unsubscribeModel]: { component: UnSubscribeModelPopup, loginRequired: true }
};

const Popup: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mountedPopup, isOpened } = usePopupState();

  const onClose = () => {
    navigate(location.pathname);
  };
  const loginRequired = mountedPopup != null ? popups[mountedPopup]?.loginRequired : false;
  if (loginRequired && !USE_SSO_LOGIN && !isLoggedIn().isAuthenticated) {
    return <Navigate to={routes.LOGIN} state={{ from: `/?popup=${mountedPopup}` }} />;
  }
  const Component = mountedPopup != null ? popups[mountedPopup]?.component : null;

  if (!Component) {
    return null;
  }

  return <Component isOpened={isOpened} onClose={onClose} />;
};

export default React.memo(Popup);
