import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import routes from 'components/Router/routes';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useLocal from 'localization';
import useStyles from './style';
import { useDispatch } from 'react-redux';
import { actions, LoginPageSelectors, LoginPageTypes } from './duck';
import CancelIcon from '@material-ui/icons/Cancel';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Mixpanel from '../../mixPanel';

const loginObjectShape = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(8, 'Password needs to have at least 8 characters.')
});

const initialValues = {
  email: '',
  password: ''
};

type FormikObj = ReturnType<typeof useFormik>;

type ContentProps = {
  formikObj: FormikObj;
  email: string;
};

const LoginDialogContent = ({ email, formikObj }: ContentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const error = useSelector(LoginPageSelectors.error);
  const hasError = useSelector(LoginPageSelectors.hasError);
  const isLoading = useSelector(LoginPageSelectors.isLoading);

  const loginHeading = useLocal('login_heading');

  const loginEmail = useLocal('login_email');

  const loginPassword = useLocal('login_password');

  const loginText = useLocal('login');

  const troubleSigning = useLocal('trouble_signing');

  const resetPassword = useLocal('reset_password');

  const didntGetAnEmail = useLocal('didnt_get_an_email');

  const resend = useLocal('resend');
  const signupForFree = useLocal('signup_for_free');
  const showReSendEmail = useSelector(LoginPageSelectors.showReSendEmail);

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleResend = () => {
    dispatch(actions.resendEmailRequest(email));
  };

  return (
    <>
      <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
        <h2>{loginHeading}</h2>
        <IconButton data-testid="api-settings-close-btn" onClick={() => navigate(routes.HOME)}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      {hasError && (
        <Alert severity="error" data-testid="server-error">
          {error}
          {showReSendEmail && (
            <p>
              {didntGetAnEmail}
              <button className={classes.resendButton} onClick={handleResend}>
                {resend}
                {isLoading && <CircularProgress size={18} />}
              </button>
            </p>
          )}
        </Alert>
      )}
      <DialogContent>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="email" error={hasError}>
            {loginEmail}
          </InputLabel>
          <OutlinedInput
            id="email"
            inputProps={{ 'data-testid': 'login-email' }}
            name="email"
            type="text"
            onChange={formikObj.handleChange}
            value={formikObj.values.email}
            labelWidth={103}
            onBlur={formikObj.handleBlur}
            error={Boolean(formikObj.errors.email && formikObj.touched.email) || hasError}
          />
          <FormHelperText
            data-testid="email-error"
            error={Boolean(formikObj.errors.email && formikObj.touched.email)}
          >
            {formikObj.touched.email && formikObj.errors.email}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="password" error={hasError}>
            {loginPassword}
          </InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            inputProps={{ 'data-testid': 'login-password' }}
            type={showPassword ? 'text' : 'password'}
            value={formikObj.values.password}
            onChange={formikObj.handleChange}
            error={Boolean(formikObj.errors.password && formikObj.touched.password) || hasError}
            onBlur={formikObj.handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          <FormHelperText
            data-testid="password-error"
            error={Boolean(formikObj.errors.password && formikObj.touched.password)}
          >
            {formikObj.touched.password && formikObj.errors.password}
          </FormHelperText>
        </FormControl>
        <DialogActions className={classes.dialogActions}>
          <Button
            type="submit"
            onClick={formikObj.submitForm}
            color="primary"
            data-testid="login-submit"
            disabled={isLoading}
            variant="contained"
          >
            {loginText}
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Button
            type="submit"
            onClick={() => {
              window.location.replace('https://daloopa.com/plg');
            }}
            color="primary"
            data-testid="login-submit"
            variant="outlined"
          >
            {signupForFree}
          </Button>
        </DialogActions>
        <Typography color="textSecondary">
          {troubleSigning} <Link to={routes.FORGET_PASSWORD}>{resetPassword}</Link>
        </Typography>
      </DialogContent>
    </>
  );
};

type Props = {
  useDialog?: boolean;
};

const Login = ({ useDialog = true }: Props) => {
  const navigate = useNavigate();

  const passwordExpiredText = useLocal('password_expired');
  const shouldResetPasswordText = useLocal('should_reset_password');

  const shouldResetPassword = useSelector(LoginPageSelectors.shouldResetPassword);

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const { state } = useLocation();

  const formikObj = useFormik<LoginPageTypes.loginFormField>({
    initialValues,
    validateOnBlur: true,
    validationSchema: loginObjectShape,
    onSubmit: ({ email, password }) => {
      Mixpanel.track('marketplace:login:form:submit', { email });
      setEmail(email);
      dispatch(actions.loginRequest(email, password, state?.from ?? routes.HOME));
    }
  });

  useEffect(() => {
    return function clearUp() {
      dispatch(actions.clearLoginState());
    };
  }, [dispatch]);

  return (
    <form onSubmit={formikObj.handleSubmit}>
      {useDialog && (
        <>
          <Dialog maxWidth="xs" open aria-labelledby="form-dialog-title">
            <LoginDialogContent email={email} formikObj={(formikObj as unknown) as FormikObj} />
          </Dialog>
        </>
      )}
      {!useDialog && (
        <LoginDialogContent email={email} formikObj={(formikObj as unknown) as FormikObj} />
      )}
      <ConfirmationDialog
        isOpen={shouldResetPassword}
        onConfirm={() => {
          dispatch(actions.setShouldResetPassword(false));
          navigate(routes.FORGET_PASSWORD, { replace: true });
        }}
        title={passwordExpiredText}
        content={shouldResetPasswordText}
        onClose={() => {
          dispatch(actions.setShouldResetPassword(false));
        }}
      />
    </form>
  );
};

export default Login;
