import { GraphQL, gql } from 'core';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { UserDetailSelectors, actions as userDetailAction } from 'userDetail';
import { UserDetailState } from 'userDetail/types';

export const setQueryParams = (params: Record<string, any>) => {
  if (window) {
    window.history.pushState({}, '', `?${queryString.stringify(params)}`);
  }
};

export const getQueryParams = (): any => {
  if (window) {
    return queryString.parse(window.location.search);
  }
  return {};
};

export const updateQueryParams = (obj: any) => {
  const params = getQueryParams();
  const updatedParams = { ...params, ...obj };
  for (const key in updatedParams) {
    if (updatedParams[key] === undefined || obj[key] === null) {
      delete updatedParams[key];
    }
  }
  setQueryParams(updatedParams);
};

export const getTimeFormatted = (seconds: number | undefined) => {
  let unit = 'min';
  let value = (seconds ?? 0) / 60; // min
  if (value >= 60) {
    value /= 60; //hours
    unit = 'hrs';
  }
  if (unit === 'hrs' && value >= 24) {
    value /= 24; //days
    unit = 'days';
  }
  return { unit, value: value.toFixed(1) };
};

export const updateNotificationToggle = (receiveModelUpdateNotification: boolean) => {
  return GraphQL.mutate({
    mutation: gql`
      mutation toggleUserModelUpdateNotification($receiveModelUpdateNotification: Boolean) {
        toggleUserModelUpdateNotification(
          receiveModelUpdateNotification: $receiveModelUpdateNotification
        ) {
          success
          receiveModelUpdateNotification
        }
      }
    `,
    variables: {
      receiveModelUpdateNotification
    },
    fetchPolicy: 'no-cache'
  });
};

export const useUserInfo = (): {
  data: UserDetailState;
  refetch: () => void;
} => {
  const dispatch = useDispatch();

  const data = useSelector(UserDetailSelectors.getUserDetails);

  const refetch = () => {
    dispatch(userDetailAction.getUserDetails());
  };

  return { data, refetch };
};
